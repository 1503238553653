.splashLoaderContainer {
  width: 90%;
  height: 70%;
  background: linear-gradient(330deg, #113257, rgb(0 35 72 / 56%));
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 200px;
  transition: 0.3s ease;
}

.splashLoaderContainer span {
  // background-color: darkgray;
  font-family: "Poppins";
  font-weight: 900;
  color: wheat;
  font-size: 20px;
}

.loaderIcon {
  animation: loadAnimation infinite ease forwards 2s;
  transition: 0.3s ease;
  margin-left: 2px;
}

@keyframes loadAnimation {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}
