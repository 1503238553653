.errorContainer {
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #000918, #1e846f);
  color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
}

.errorCode {
  font-size: 170px;
}
