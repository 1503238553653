.printIdCardWrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.printIdCardContainer {
  width: 495px;
  height: 250px;
}

.showDisplayMessage {
  width: 100%;
  height: auto;
  padding: 10px;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 520px) {
  .printIdCardWrapper {
    rotate: 270deg;
    margin-top: 241px;
    margin-left: -65px;
    padding-top: 0px;
  }
}
