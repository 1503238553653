.loginContainer {
  width: 100%;
  height: 520px;
  display: flex;
}

.loginContainerOne {
  width: 50%;
  height: 100%;
  //   background-color: darkcyan;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  overflow: hidden;
}

.loginDesignContainer {
  width: 65%;
  height: 70%;
  //   background-color: darkgoldenrod;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 30px;
}

.loginContainerTwo {
  width: 50%;
  height: 100%;
  //   background-color: lightsalmon;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  overflow: hidden;
}

.loginSection {
  width: 80%;
  height: auto;
  //   border: 1px solid silver;
  overflow: hidden;
  box-shadow: 0px 0px 10px 5px lightgray;
}

.eachFieldContainer {
  width: 100%;
  height: auto;
  //   background-color: darkcyan;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
}

.captchaFieldContainer {
  width: 100%;
  height: auto;
  //   background-color: rgb(255, 0, 0);
  margin-top: 2px;
  display: flex;
  padding: 0px 10px;
}

.eachFieldContainerText {
  width: 100%;
  height: auto;
  background-color: #dbdbdb;
  text-align: center;
}

#submitButtonDesign {
  border: none;
  width: 80px;
  height: 40px;
  background-color: dodgerblue;
  color: white;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0px 0px 1px 0px #1a8eeb;
}

#submitButtonDesign:hover {
  background-color: rgb(22, 109, 195);
}
#resetButtonDesign {
  border: none;
  width: 80px;
  height: 40px;
  // background-color: #ff005d;
  background-color: #e10053;
  color: white;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0px 0px 1px 0px #1a8eeb;
}

#resetButtonDesign:hover {
  background-color: #880032;
}

@media (max-width: 650px) {
  .loginContainerOne {
    display: none;
  }
  .loginContainerTwo {
    width: 100%;
    justify-content: center;
    padding-left: 0px;
  }
}
