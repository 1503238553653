@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

.authContainer {
  width: 100%;
  background-color: white;
}

.mainLayer {
  width: 100%;
  min-height: 550px;
}
.optionButtonContainer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;
  padding: 5px 10px;
  position: sticky;
  bottom: 0px;
  background-color: white;
}
.optionButton {
  border: none;
  padding: 8px;
  font-weight: bolder;
  border: 1px solid silver;
  border-radius: 10px;
}

.errorMessage {
  width: 100%;
  height: 200px;
  // background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  color: darkolivegreen;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 600;
}

.noteContainer {
  width: 100%;
  height: auto !important;
  // background-color: red;
  color: #969696;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-family: "Poppins";
  font-weight: 500;
}
@media (max-width: 400px) {
  .optionButton {
    font-size: 10px !important;
  }
}

@media (max-width: 520px) {
  .forIdCardInside #__next {
    rotate: 270deg;
  }
}
