.userDetialsContainer {
  //   background-color: red;
  width: 100%;
  height: auto;
  min-height: 550px;
  padding: 20px 5px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;

  background-image: url("../../../public/kheci_watermark.png");
  background-repeat: no-repeat;
  background-position: center 50px;
  // background-position: center 20px;
  background-size: contain;
}
.headerFirst {
  width: 100%;
  height: auto;
  //   background-color: darkblue;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.headerFisrtBoxOne {
  width: auto;
  height: auto;
  background-color: darkcyan;
  color: lavender;
  padding: 5px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: bold;
}
.headerFirstBoxTwo {
  width: auto;
  height: auto;
  //   background-color: darkgoldenrod;
  font-size: 14px;
}

.headerSecond {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  //   background-color: red;
  margin-top: 20px;
  font-size: 30px;
  font-family: "Poppins";
  font-weight: bolder;
  border-top: 1px solid lightgray;
}

.eachDetailsContainer {
  width: 100%;
  height: auto;
  // background-color: red;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.eachDetailsContainerOneEachDiv {
  width: 100%;
  height: auto;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 10px;
}

.eachDetailsContainerOneEachDivText {
  font-size: 20px;
  padding-left: 10px;
  font-weight: bolder;
}

.eachDetailsContainerTwo {
}

@media (max-width: 320px) {
}

@media (max-width: 375px) {
  .headerFirstBoxTwo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 10px;
  }

  .headerSecond {
    font-size: 18px;
  }
}
@media (max-width: 510px) {
  .eachDetailsContainer {
    flex-direction: column-reverse;
  }
  .eachDetailsContainerOne {
    margin-top: 20px;
  }

  .eachDetailsContainerOneEachDiv {
    border-bottom: 1px dashed lightgray;
  }

  // This below only for background color for mobile devices
  #md-bg-yes {
    background: linear-gradient(180deg, #00ff735e, #0087897d);
  }
}
