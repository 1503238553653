.paymentDetailsContainer {
  //   background-color: red;
  width: 100%;
  height: auto;
  min-height: 550px;
}
.paymentDetailsHeader {
  width: 100%;
  height: auto;
  font-size: 25px;
  font-family: "Poppins";
  font-weight: bolder;
  //   background-color: darkblue;
  padding: 20px 10px;
  border-bottom: 1px solid lightgray;
}

.paymentDetailsError {
  width: 100%;
  height: auto;
  font-size: 25px;
  font-family: "Poppins";
  font-weight: bolder;
  //   background-color: darkblue;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 375px) {
  .tableHeaderText {
    font-size: 13px;
  }

  .paymentDetailsError {
    font-size: 13px;
  }
}

@media (max-width: 500px) {
  .tableDataText {
    font-size: 10px;
  }

  .paymentDetailsError {
    font-size: 16px;
  }
}
