.footerContainer {
  width: 100%;
  height: 80px;
  //   background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerText {
  width: auto;
  height: 100%;
  //   background-color: wheat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-weight: bold;
  font-size: 14px;
}
