.verifyCertificateContainer {
  //   background-color: red;
  width: 100%;
  height: auto;
  min-height: 550px;
}
.verifyCertificateHeader {
  width: 100%;
  height: auto;
  font-size: 25px;
  font-family: "Poppins";
  font-weight: bolder;
  //   background-color: darkblue;
  padding: 20px 10px;
  border-bottom: 1px solid lightgray;
}

.verifyCertificateInput {
  width: 100%;
  height: auto;
  // background-color: darkblue;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.verifyIconText {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-family: "Poppins";
  font-weight: 800;
  padding-top: 20px;
}

.verifyCertificateError {
  width: 100%;
  height: auto;
  font-size: 25px;
  font-family: "Poppins";
  font-weight: bolder;
  //   background-color: darkblue;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verifyButton {
  border: none;
  padding: 5px;
  background: linear-gradient(270deg, #0b376b, #00a252);
  color: white;
  font-size: 15px;
  font-weight: 700;
  font-family: "Poppins";
}
.verifyButton:hover {
  background: linear-gradient(90deg, #0b376b, #00a252);
}

@media (max-width: 375px) {
  .certificateTableHeaderText {
    font-size: 13px;
  }

  .verifyCertificateError {
    font-size: 13px;
  }
}

@media (max-width: 430px) {
  .verifyCertificateInput {
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .certificateTableDataText {
    font-size: 10px;
  }

  .verifyCertificateError {
    font-size: 16px;
  }
}
