.studentHeader {
  width: 100%;
  height: 80px;
  background-color: whitesmoke;
  display: flex;
  background: linear-gradient(135deg, transparent, #0e3562);
  color: white;
}
.studentHeaderOne {
  width: 60%;
  height: 100%;
  // background-color: red;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.studentHeaderTwo {
  width: 40%;
  height: 100%;
  //   background-color: gold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.studentHeaderNav {
  padding: 0px;
  margin: 0px;
}
.studentHeaderNav li {
  display: inline-block;
  padding: 10px;
  list-style: none;

  //   background-color: cadetblue;
}
.studentHeaderNavTextLink {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.studentHeaderNavTextLink:hover {
  text-decoration: underline;
  color: inherit;
}
